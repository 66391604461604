export enum Route {
  dashboard = "/dashboard",
  clients = "/clients",
  inbox = "/inbox",
  reviews = "/reviews",
  approved = "/approved",
  admin = "/admin",
  new = "new",
  edit = "edit",
  campaigns = "campaigns",
  settings = "settings",
  creative = "creative",
  profile = "profile",
  assets = "assets",
  theme = "theme",
  notFound = "/404",
  uiKit = "/ui-kit",
  emails = "emails",
  landingPages = "landing-pages",
  emptyJourneyNodes = "empty-journey-nodes",
  emailBuilder = "email-builder",
  emailPreview = "email-preview",
  lpBuilder = "landing-page-builder",
  lpPreview = "landing-page-preview",
  login = "/login",
  logout = "/logout",
  versions = "versions",
  themeBuilder = "theme-builder",
  themePreview = "theme-preview",
  details = "details",
  preview = "preview",
  basic = "basic",
  team = "team-members",
  emailTypes = "email-types",
  design = "design",
}

export enum PreviewRoute {
  color = "/color",
  fonts = "/fonts",
  typography = "/typography",
  button = "/button",
}

const buildPathSegments = (...segments: (string | undefined)[]) =>
  segments
    .filter(Boolean)
    .map((segment) => segment?.replace(/^\/+/, "").replace(/\/+$/, ""))
    .join("/")
    .replace(/^\/+/, "");

export const buildPath = (...segments: (string | undefined)[]) =>
  "/" + buildPathSegments(...segments);

export const buildRelativePath = (...segments: (string | undefined)[]) =>
  buildPathSegments(...segments);

export const getClientPaths = ({ clientId = ":clientId" } = {}) => {
  const clientPath = buildPath(Route.clients, clientId);

  return {
    clientCampaignsPath: buildPath(clientPath, Route.campaigns),
    clientThemePath: buildPath(clientPath, Route.theme),
    clientProfilePath: buildPath(clientPath, Route.profile),
  } as const;
};

export const getCampaignPaths = ({ clientId = ":clientId", campaignId = ":campaignId" } = {}) => {
  const campaignPath = buildPath(Route.clients, clientId, Route.campaigns, campaignId);

  return {
    campaignCreativePath: buildPath(campaignPath, Route.creative),
  } as const;
};

export const getTouchpointPaths = ({
  clientId = ":clientId",
  campaignId = ":campaignId",
  touchpointId = ":touchpointId",
} = {}) => {
  const touchpointPath = buildPath(
    Route.clients,
    clientId,
    Route.campaigns,
    campaignId,
    Route.creative,
    touchpointId,
  );

  return {
    touchpointPreviewPath: buildPath(touchpointPath, Route.preview),
    touchpointDetailsPath: buildPath(touchpointPath, Route.details),
    touchpointVersionsPath: buildPath(touchpointPath, Route.versions),
    touchpointPath,
  } as const;
};
