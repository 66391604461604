import React, { useContext, useState, forwardRef, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ButtonGroup, IconButton, MenuButton, Tooltip, useDisclosure } from "@chakra-ui/react";
import Select from "components/forms/select/select";
import Button from "components/forms/button/button";
import VersionEditModal, {
  FormType,
  onSubmitFormProps,
} from "components/modals/version-edit-modal/version-edit-modal";
import ConfirmArchiveModal from "components/modals/confirm-archive-modal/confirm-archive-modal";
import ConfirmationModal from "components/modals/confirmation-modal/confirmation-modal";
import TouchpointApproveSendFeedback from "containers/admin/clients/touchpoint/components/touchpoint-approve-send-feedback/touchpoint-approve-send-feedback";
import ClientReviewStatusIndicator from "containers/admin/clients/touchpoint/components/client-review-status-indicator/client-review-status-indicator";
import { CustomTooltip } from "components/partials/tooltip/tooltip";
import toast, { Toast } from "components/partials/toast/toast";
import ToastButton from "components/partials/toast/toast-button";
import { CloneTouchpointModal } from "components/modals/clone-touchpoint-modal/clone-touchpoint-modal";

import CommentContext from "contexts/comment-context";

import {
  useCurrentCampaign,
  useCurrentClient,
  useCurrentTouchpoint,
  useCurrentUser,
} from "state/ducks";
import { setTouchpointVersions, touchpointVersionsSelector } from "state/ducks/touchpoint";
import { useGetTouchpointVersionStatusesQuery } from "state/api/dictionary";

import { Touchpoint } from "models";
import TouchpointVersion, { TouchpointVersionAttributes } from "models/touchpoint-version";

import { getDisplayTouchpointType, getErrorMessage, getTouchpointType } from "utilities";
import { hasPermission, hasRole } from "utilities/user";
import { Route as AppRoute } from "utilities/app-routes";
import {
  canApproveSendFeedbackOnTouchpoint,
  getIsButtonGroupShown,
  getIsApproved,
  getUserCanPublish,
  getSuccessMessage,
  getFailMessage,
} from "utilities/touchpoint";

import { ContentStatus, ClientReviewStatus, DEFAULT_CLIENT_REVIEW_STATUSES } from "types";
import { TouchpointType } from "types/touchpoint";
import { Permission, Role } from "types/auth";

import Icons from "assets";
import {
  ActionsMenu,
  ActionsMenuItem,
} from "containers/admin/clients/touchpoint/components/actions-menu/actions-menu";
import { MdMoreVert } from "react-icons/md";
import { ApproveAsClientModal } from "components/modals/approve-as-client-modal/approve-as-client-modal";
import { useBuilderAccess } from "containers/admin/clients/touchpoint/components/builder/use-builder-access";
interface TouchpointPreviewTabHeaderProps {
  isSummaryLoading?: boolean;
  selectedVersion?: TouchpointVersionAttributes;
  setSelectedVersion: (version: TouchpointVersionAttributes) => void;
}

interface PreviewCustomButtonType {
  isDisabled: boolean;
  isLoading: boolean;
  onClick: () => void;
  onMouseEnter?: () => void;
  isUserCanPublish: boolean;
}

const PreviewCustomButton = forwardRef<HTMLDivElement, PreviewCustomButtonType>(
  ({ isUserCanPublish, isDisabled, onMouseEnter, ...props }, ref) => {
    return (
      <div ref={ref} onMouseEnter={isDisabled ? onMouseEnter : undefined}>
        <Button
          variant="primary"
          size="sm"
          isDisabled={isDisabled}
          aria-label={isUserCanPublish ? "Publish" : "Edit"}
          {...props}
          onMouseEnter={isDisabled ? undefined : onMouseEnter}>
          {isUserCanPublish ? "Publish" : "Edit"}
        </Button>
      </div>
    );
  },
);

const TooltipText = () => (
  <div className="flex w-[350px]">
    <Icons.Info width={26} customClass="text-neutral-2 mr-1" />
    Publishing is disabled due to open action items in comments
  </div>
);

export const ActionsMenuTrigger = () => {
  const title = "More actions";
  return (
    <Tooltip label={title} hasArrow placement="top">
      <MenuButton
        as={IconButton}
        icon={<MdMoreVert />}
        fontSize="xl"
        aria-label={title}
        title={title}
        background="white!important"
        shadow="md"
      />
    </Tooltip>
  );
};

export const TouchpointPreviewTabHeader = ({
  isSummaryLoading,
  selectedVersion,
  setSelectedVersion,
}: TouchpointPreviewTabHeaderProps) => {
  const navigate = useNavigate();
  const { accessBuilder } = useBuilderAccess();
  const currentClient = useCurrentClient();
  const currentCampaign = useCurrentCampaign();
  const currentTouchpoint = useCurrentTouchpoint();
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const touchpointVersions = useSelector(touchpointVersionsSelector);
  const { data: touchpointVersionStatuses = [] } = useGetTouchpointVersionStatusesQuery();

  const versions = touchpointVersions.items;

  const getClientVersionStatuses = (status?: string) => {
    if (!status) {
      return DEFAULT_CLIENT_REVIEW_STATUSES;
    }

    return (
      touchpointVersionStatuses.find((el) => el.status === status) || DEFAULT_CLIENT_REVIEW_STATUSES
    );
  };

  const editVersionModal = useDisclosure();
  const newDraftModal = useDisclosure();
  const confirmPublishModal = useDisclosure();
  const confirmArchiveModal = useDisclosure();
  const cloneTouchpointModal = useDisclosure();
  const approveAsClientModal = useDisclosure();

  const { commentsSummary } = useContext(CommentContext);
  const userCanWrite = hasPermission(currentUser, Permission.PERM_TOUCHPOINT_WRITE);
  const isClientAdmin = hasRole(currentUser, Role.ROLE_CLIENT_ADMIN);
  const isClientUser = hasRole(currentUser, Role.ROLE_CLIENT_USER);

  const selectedVersionCanBeArchived = [
    ClientReviewStatus.DRAFT,
    ClientReviewStatus.SYSTEM_DRAFT,
  ].includes(selectedVersion?.status as ClientReviewStatus);

  const selectedVersionIsPublished = selectedVersion?.status === ContentStatus.PUBLISHED;
  const touchpointIsEmail = selectedVersion?.type === TouchpointType.EMAIL;

  const [nextMinorMajorVersions, setNextMinorMajorVersions] = useState<string[]>();
  const [isSendBtnLoading, setIsSendBtnLoading] = useState<boolean>(false);
  const [approvePublishLoading, setApprovePublishLoading] = useState<boolean>(false);

  const handleEditEmail = () => {
    accessBuilder(selectedVersion);
  };

  const handleSelectVersion = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    const newSelectedTouchpoint = versions.find((version) => version.id === id);

    if (newSelectedTouchpoint) {
      setSelectedVersion(newSelectedTouchpoint);
    }
  };

  const refreshTouchpointVersions = useCallback(async () => {
    const results = await TouchpointVersion.all({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      touchpointId: currentTouchpoint.id,
    });
    const resultsAttributes = results.items.map((v) => v.attributes);
    dispatch(setTouchpointVersions({ ...results, items: resultsAttributes }));
    return resultsAttributes;
  }, [currentClient.id, currentCampaign.id, currentTouchpoint.id, dispatch]);

  useEffect(() => {
    /** Always show the latest version when arriving at this tab */
    const fetchLatestVersion = async () => {
      try {
        const versions = await refreshTouchpointVersions();
        if (versions.length > 0) {
          setSelectedVersion(versions[0]);
        }
      } catch (error) {
        console.error("Failed to fetch the latest touchpoint version:", error);
      }
    };

    fetchLatestVersion();
  }, [refreshTouchpointVersions, setSelectedVersion]);

  const handleEditVersionDetails = () => {
    editVersionModal.onOpen();
  };

  const handleArchiveDraft = () => {
    confirmArchiveModal.onOpen();
  };

  const handleArchiveVersion = (touchpoint?: TouchpointVersionAttributes) => {
    if (touchpoint) {
      const touchpointType = getTouchpointType(touchpoint);
      const formattedTouchpointType = getDisplayTouchpointType(touchpointType);

      TouchpointVersion.patchTouchpointStatus({
        campaignId: currentCampaign.id,
        clientId: currentClient.id,
        touchpointId: currentTouchpoint.id,
        touchpointVersionId: touchpoint.id,
        status: ContentStatus.ARCHIVED_DRAFT,
      })
        .then(() => {
          refreshTouchpointVersions();
          toast.success({
            data: {
              title: `${formattedTouchpointType} version ${touchpoint.version} archived.`,
            },
          });
        })
        .catch((err) => {
          toast.error({
            data: {
              title: "Failed to archive Touchpoint",
              message: getErrorMessage(err?.response?.data),
            },
          });
        });
    }
    confirmArchiveModal.onClose();
  };

  const handleCreateDraftFromVersion = () => {
    Touchpoint.nextVersions({
      clientId: currentClient.id,
      campaignId: currentCampaign.id,
      id: currentTouchpoint.id,
    })
      .then((response) => {
        let { nextMinorVersion, nextMajorVersion } = response.data;
        setNextMinorMajorVersions([nextMinorVersion, nextMajorVersion]);
      })
      .then(() => newDraftModal.onOpen())
      .catch((err) => {
        toast.error({
          data: {
            title: "Failed to create draft",
            message: getErrorMessage(err?.response?.data),
          },
        });
      });
  };

  const handleSubmitEditVersion = ({
    id,
    version,
    versionNotes,
    visibleToClient,
  }: onSubmitFormProps) => {
    if (id) {
      TouchpointVersion.edit({
        clientId: currentClient.id,
        campaignId: currentCampaign.id,
        touchpointId: currentTouchpoint.id,
        id: id,
        attributes: {
          version: version,
          versionNotes: versionNotes,
          visibleToClient: visibleToClient,
        },
      })
        .then(() => {
          toast.success({
            data: {
              title: "Version details updated",
            },
          });
          refreshTouchpointVersions();
        })
        .catch((err) => {
          toast.error({
            data: {
              title: "Failed to edit version",
              message: getErrorMessage(err?.response?.data),
            },
          });
        });
    }
    editVersionModal.onClose();
  };

  const handleSubmitNewDraft = ({
    id,
    version,
    versionNotes,
    visibleToClient,
  }: onSubmitFormProps) => {
    if (id) {
      TouchpointVersion.createDraft({
        campaignId: currentCampaign.id,
        clientId: currentClient.id,
        touchpointId: currentTouchpoint.id,
        id: id,
        version: version,
        versionNotes: versionNotes,
        visibleToClient: visibleToClient,
      })
        .then(() => {
          toast.success({
            data: {
              title: "New draft created",
            },
          });
          return refreshTouchpointVersions();
        })
        .then((refreshedVersions) => {
          if (refreshedVersions.length > 0) {
            // The new draft will be the newest version
            setSelectedVersion(refreshedVersions[0]);
          }
        })
        .catch((err) => {
          toast.error({
            data: {
              title: "Failed to create draft",
              message: getErrorMessage(err?.response?.data),
            },
          });
        });
    }
    newDraftModal.onClose();
  };

  const handleTouchpointStatusSending = (newStatus: string) => {
    //ping loading on btn
    const { CLIENT_REVIEW, REJECTED, APPROVED, PUBLISHED } = ClientReviewStatus;

    (newStatus === REJECTED || newStatus === CLIENT_REVIEW) && setIsSendBtnLoading(true);
    (newStatus === APPROVED || newStatus === PUBLISHED) && setApprovePublishLoading(true);

    //patch new status
    TouchpointVersion.patchTouchpointStatus({
      clientId: currentClient?.id,
      campaignId: currentCampaign?.id,
      touchpointId: currentTouchpoint?.id,
      touchpointVersionId: selectedVersion?.id,
      status: newStatus,
    })
      .then(() => {
        const currentTouchpointVersionsStatus = { ...touchpointVersions.items[0] };
        currentTouchpointVersionsStatus.status = newStatus;

        const newVersions = [...(touchpointVersions?.items ?? [])];

        newVersions[0] = currentTouchpointVersionsStatus;

        dispatch(setTouchpointVersions({ ...touchpointVersions, items: newVersions }));

        if (selectedVersion) {
          setSelectedVersion({
            ...selectedVersion,
            status: newStatus,
          });
        }

        if (newStatus === APPROVED || newStatus === REJECTED) {
          const toastWithAction = toast.success({
            data: {
              title: getSuccessMessage(newStatus),
            },
            autoClose: false,
            // TODO: Test this before removing comment
            // children: (
            //   <ToastButton
            //     size="sm"
            //     onClick={() => {
            //       toast.dismiss(toastWithAction);
            //       navigate(AppRoute.reviews);
            //     }}>
            //     Back to Reviews
            //   </ToastButton>
            // ),
            render: ({ data }) => (
              <Toast data={data}>
                <ToastButton
                  size="sm"
                  onClick={() => {
                    toast.dismiss(toastWithAction);
                    navigate(AppRoute.reviews);
                  }}>
                  Back to Reviews
                </ToastButton>
              </Toast>
            ),
          });
        } else {
          toast.success({
            data: {
              title: getSuccessMessage(newStatus),
            },
          });
        }
      })
      .catch((err) => {
        const failMessage = getFailMessage(newStatus);
        toast.error({
          data: {
            title: failMessage,
            message: getErrorMessage(err?.response?.data),
          },
        });
      })
      .finally(() => {
        setIsSendBtnLoading(false);
        setApprovePublishLoading(false);
      });

    confirmPublishModal.onClose();
  };

  const clientVersionStatuses = getClientVersionStatuses(selectedVersion?.status);

  const canApproveSendFeedback = canApproveSendFeedbackOnTouchpoint(
    currentUser,
    clientVersionStatuses?.status,
  );

  const isApproved = getIsApproved(clientVersionStatuses?.status);

  const canApproveAsClient =
    !isClientAdmin &&
    !isClientUser &&
    !isApproved &&
    ![ContentStatus.ARCHIVED_DRAFT, ContentStatus.REJECTED].includes(
      clientVersionStatuses?.status as ContentStatus,
    );

  const isUserCanPublish = getUserCanPublish(currentUser);

  const isClientReviewStatusIndicatorShown =
    !isClientAdmin &&
    !isClientUser &&
    !!clientVersionStatuses?.clientReviewStatusName &&
    selectedVersion?.version === versions[0]?.version;

  const isButtonGroupShown =
    userCanWrite &&
    getIsButtonGroupShown(clientVersionStatuses) &&
    isClientReviewStatusIndicatorShown;

  return (
    <div className="flex items-center justify-between w-full mb-5" data-testid="preview-tab-header">
      <div className="flex">
        <Select width={326} value={selectedVersion?.id} onChange={handleSelectVersion}>
          {versions.map(({ id, status, version }) => {
            const currentClientVersionStatuses = getClientVersionStatuses(status);

            return (
              <option key={id} value={id}>
                {`v${version} (${currentClientVersionStatuses?.versionStatusName})`}
              </option>
            );
          })}
        </Select>
        {isClientReviewStatusIndicatorShown && (
          <ClientReviewStatusIndicator clientVersionStatuses={clientVersionStatuses} />
        )}
      </div>
      <ButtonGroup ml={6} spacing={4} alignItems="center">
        {isButtonGroupShown && (
          <>
            {isApproved ? (
              <CustomTooltip isOpen={!!commentsSummary.actionItemCount} text={<TooltipText />}>
                <PreviewCustomButton
                  isLoading={isSummaryLoading || approvePublishLoading || false}
                  isDisabled={!!commentsSummary.actionItemCount}
                  onClick={isUserCanPublish ? () => confirmPublishModal.onOpen() : handleEditEmail}
                  isUserCanPublish={isUserCanPublish}
                />
              </CustomTooltip>
            ) : (
              <Button
                isLoading={isSendBtnLoading}
                variant="primary"
                size="sm"
                onClick={() => handleTouchpointStatusSending(ClientReviewStatus.CLIENT_REVIEW)}>
                Send to Client
              </Button>
            )}
          </>
        )}

        {isClientAdmin && canApproveSendFeedback && (
          <TouchpointApproveSendFeedback
            isSendBtnLoading={isSendBtnLoading}
            approvePublishLoading={approvePublishLoading}
            onClick={(status: string) => handleTouchpointStatusSending(status)}
          />
        )}

        {isClientUser && (
          <Button variant="primary" size="sm" onClick={() => navigate(AppRoute.reviews)}>
            Back to Reviews
          </Button>
        )}

        {!isClientAdmin && !isClientUser && (
          <ActionsMenu trigger={<ActionsMenuTrigger />}>
            {selectedVersionIsPublished ? (
              <>
                <ActionsMenuItem.CreateDraftFromVersion onClick={handleCreateDraftFromVersion} />

                <ActionsMenuItem.CloneTouchpoint onClick={cloneTouchpointModal.onOpen} />
              </>
            ) : (
              <>
                <ActionsMenuItem.EditVersionDetails onClick={handleEditVersionDetails} />

                <ActionsMenuItem.EditTouchpoint
                  onClick={handleEditEmail}
                  isEmail={touchpointIsEmail}
                />
                <ActionsMenuItem.CreateDraftFromVersion onClick={handleCreateDraftFromVersion} />

                <ActionsMenuItem.CloneTouchpoint onClick={cloneTouchpointModal.onOpen} />

                {canApproveAsClient && (
                  <ActionsMenuItem.ApproveAsClient onClick={approveAsClientModal.onOpen} />
                )}

                {isApproved && (
                  <ActionsMenuItem.SendToClient
                    onClick={() => handleTouchpointStatusSending(ClientReviewStatus.CLIENT_REVIEW)}
                  />
                )}

                <ActionsMenuItem.Publish
                  onClick={confirmPublishModal.onOpen}
                  isEmail={touchpointIsEmail}
                />

                {selectedVersionCanBeArchived && (
                  <ActionsMenuItem.ArchiveDraft onClick={handleArchiveDraft} />
                )}
              </>
            )}
          </ActionsMenu>
        )}
      </ButtonGroup>

      {/* End Header / Begin Modals */}

      <VersionEditModal
        {...editVersionModal}
        id={selectedVersion?.id}
        placeholderNotes={selectedVersion?.versionNotes}
        parentName={currentCampaign.name}
        formType={FormType.EDIT}
        version={selectedVersion?.version || "1.0"}
        name={selectedVersion?.name ? selectedVersion.name : currentTouchpoint.name}
        onSubmit={handleSubmitEditVersion}
        helperText="Edit the version description"
        saveText="Save"
        visibleToClient={selectedVersion?.visibleToClient}
        nextMinorMajorVersions={nextMinorMajorVersions}
      />

      <VersionEditModal
        {...newDraftModal}
        id={selectedVersion?.id}
        parentName={currentCampaign.name}
        formType={FormType.NEW_VERSION}
        version={selectedVersion?.version || "1.0"}
        name={selectedVersion?.name ? selectedVersion.name : currentTouchpoint.name}
        onSubmit={handleSubmitNewDraft}
        nextMinorMajorVersions={nextMinorMajorVersions}
      />

      <ConfirmArchiveModal
        {...confirmArchiveModal}
        currentTouchpoint={selectedVersion}
        onSubmit={() => handleArchiveVersion(selectedVersion)}
      />

      <CloneTouchpointModal {...cloneTouchpointModal} selectedVersion={selectedVersion} />

      <ConfirmationModal
        {...confirmPublishModal}
        onConfirm={() => handleTouchpointStatusSending(ClientReviewStatus.PUBLISHED)}
        headline={
          touchpointIsEmail
            ? "Are you sure you want to publish this email to Iterable?"
            : "Are you sure you want to publish this landing page?"
        }
        message="This will replace the current published version. This action cannot be undone."
        confirmButtonText={touchpointIsEmail ? "Yes, publish to Iterable" : "Yes, publish"}
      />

      <ApproveAsClientModal
        {...approveAsClientModal}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
      />
    </div>
  );
};
